import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {HashRouter} from 'react-router-dom';
import AppRoute from './assets/route/AppRoute';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <HashRouter>
        <AppRoute></AppRoute>
        </HashRouter>
      </Fragment>
    );
  }
}

App.propTypes = {};

export default App;
