import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Navbar, Container, Row, Col, Button} from 'react-bootstrap';

class OrderForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                
            </div>
        );
    }
}

OrderForm.propTypes = {};

export default OrderForm;
