import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Col, Row,Card, Button, Form, Container } from 'react-bootstrap';

class UserOnBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Container className="TopSection animated slideInDown">
                    <Row className="d-flex p-1 justify-content-center">
                        <Col className="shadow-sm bg-white mt-2" md={8} lg={8} sm={12} xs={12}>
                            <Row className="text-center ">
                                <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Form className='onboardForm'>
                                        <h4 className="section-title">Contact Us</h4>
                                        <h6 className="section-sub-title">Please Enter Your Mobile No, And Go Next</h6>
                                        <Form.Group className='mb-3 mt-3'>
                                            <Form.Control type="text" placeholder='Your Mobile' />
                                        </Form.Group>                                              
                                        <Form.Group>
                                            <Button className='w-100 btn btn-block pure-material-button-contained site-btn'>Next</Button>
                                        </Form.Group>                                                                            
                                    </Form>
                                </Col>
                                <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                                    <img className="onboardBanner" src="https://toihid.com/wp-content/uploads/2023/04/otppagebanner.png" alt="login banner"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>  
            </Fragment>
        );
    }
}

UserOnBoard.propTypes = {};

export default UserOnBoard;
