import React, {Component, Fragment} from 'react';
import { Button } from 'react-bootstrap';
import {Link} from "react-router-dom";
import MegaMenuLoader from '../placeholder/MegaMenuLoader';
class MegaMenu extends Component {

    constructor(props) {
      super(props);
      this.state = {
            isLoading: 'TopSection',
            mainDiv: "d-none",
            menuData: this.props.menuData
      };
     
    }

    componentDidMount(){
      if(this.state.menuData){
        this.setState({isLoading:"d-none", mainDiv:''});
      }
    }

    MenuItemClick=(event)=>{
      event.target.classList.toggle('active');
      let panel = event.target.nextElementSibling;
      if(panel.style.maxHeight){
        panel.style.maxHeight=null;
      }
      else{
          panel.style.maxHeight=panel.scrollHeight+ "px";
      }      
    }

    render() {

        const parentList=this.props.menuData;
        //this.setState({isLoading:"d-none", mainDiv:''});
          const myView = parentList.map((parentList,i)=>{
            return <div key={i.toString()}>
              <button className='accordion' onClick={this.MenuItemClick} > <img className='h-auto' src={parentList.parentCategoryImg}></img> {parentList.parentCategoryName} </button>
              <div className='panel'>
                  <ul>
                    {
                      (parentList.subCategory).map((childList,i)=>{
                        return <li> <Link to={"productListBySubCategory/"+ childList.cat1_name+"/" + childList.cat2_name} className='accordingItem'> {childList.cat2_name} </Link></li>;
                      })
                    }
     
                  </ul>
              </div>
            </div>
          }); 
        return (
            <div>
              <MegaMenuLoader isLoading={this.state.isLoading}> </MegaMenuLoader>
              <div className={this.state.mainDiv}>
                <div className="accordionMenuDiv animated slideInDown">
                    <div className="accordionMenuDivInside">
                        {myView}
                    </div>
                </div>
              </div>

            </div>

        );
    }
}
export default MegaMenu;