import React, {Component,Fragment} from 'react';
import {Container, Row, Col, Breadcrumb} from "react-bootstrap";
import {Link, json} from "react-router-dom";
import PropTypes from 'prop-types';
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
   
class ProductDetails extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            title: '',
            price: '',
            special_price: '',
            brand: '',
            star: '',
            product_code: '',

            product_id: '',
            img1: '',
            img2: '',
            img3: '',
            img4: '',
            des: '',
            color: '',
            size: '',
            details: ''

        };
    }

    componentDidMount(){
        const url = window.location.href;
        const slug = url.substring(url.lastIndexOf('/') + 1);             
        Axios.get(ApiUrl.ProductDetailsById(slug)).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let productData = response.data;
                this.setState({
                    title: productData['productList'][0]['title'],
                    price: productData['productList'][0]['price'],
                    special_price: productData['productList'][0]['special_price'],
                    brand: productData['productList'][0]['brand'],
                    star: productData['productList'][0]['star'],
                    product_code: productData['productList'][0]['product_code'],

                    product_id: productData['productDetails'][0]['product_id'],
                    img1: productData['productDetails'][0]['img1'],
                    img2: productData['productDetails'][0]['img2'],
                    img3: productData['productDetails'][0]['img3'],
                    img4: productData['productDetails'][0]['img4'],
                    des: productData['productDetails'][0]['des'],
                    color: productData['productDetails'][0]['color'],
                    size: productData['productDetails'][0]['size'],
                    details: productData['productDetails'][0]['details'],


                });
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        }); 
        
    }

    imgOnClick(event){
        let imgSrc = event.target.getAttribute('src');
        let previewImg = document.getElementById('previewImg');
        ReactDOM.findDOMNode(previewImg).setAttribute('src',imgSrc);

    }

    render() {
     

        return (
            <Fragment>
                <Container className='BetweenTwoSections pt-5'>
                    <Row className='p-2'>
                        <Col  className='shadow-sm bg-white pb-3 mt-4' lg={12} md={12} sm={12} xs={12} >
                            

                            <Row>
                                <Col className='p-3 productDetails' lg={6} md={6} sm={12} xs={12} >
                                    <img id='previewImg' className='w-100' src={this.state.img1}></img>
                                    <Container className='my-3'>
                                        <Row>
                                            <Col className='p-1 m-0 image-box' lg={3} md={3} sm={3} xs={3} >
                                                <img onClick={this.imgOnClick} className='w-100 Product-sm-img' src={this.state.img1}></img>
                                            </Col>
                                            <Col className='p-1 m-0 image-box' lg={3} md={3} sm={3} xs={3} >
                                                <img onClick={this.imgOnClick} className='w-100 Product-sm-img' src={this.state.img2}></img>
                                            </Col>     
                                            <Col className='p-1 m-0 image-box' lg={3} md={3} sm={3} xs={3} >
                                                <img onClick={this.imgOnClick} className='w-100 Product-sm-img' src={this.state.img3}></img>
                                            </Col>
                                            <Col className='p-1 m-0 image-box' lg={3} md={3} sm={3} xs={3} >
                                                <img onClick={this.imgOnClick} className='w-100 Product-sm-img' src={this.state.img4}></img>
                                            </Col>                                  
                                        </Row>
                                    </Container>                                    
                                </Col>
                                <Col className='p-3' lg={6} md={6} sm={12} xs={12}>
                                    <h5 className='Product-Name'> {this.state.title} </h5>
                                    <h6 className='section-sub-title'> {this.state.des}</h6>
                                    <div className='input-group'>
                                        <div className='Product-price-card d-inline'> Regular Price 2000</div>
                                        <div className='Product-price-card d-inline'> 50% Discount</div>
                                        <div className='Product-price-card d-inline'> New Price 100</div>
                                    </div>
                                    <h6 className='mt-2'> Choose Color</h6>
                                    <div className='input-group'>
                                        <div className='form-check mx-1'>
                                            <input className='form-check-input' type='radio' name='exampleRadio' id='exampleRadio' />
                                            <label className='form-check-label' htmlFor='exampleRadio'>Black</label>
                                        </div>
                                        <div className='form-check mx-1'>
                                            <input className='form-check-input' type='radio' name='exampleRadio' id='exampleRadio' />
                                            <label className='form-check-label' htmlFor='exampleRadio'>Green</label>
                                        </div>
                                        <div className='form-check mx-1'>
                                            <input className='form-check-input' type='radio' name='exampleRadio' id='exampleRadio' />
                                            <label className='form-check-label' htmlFor='exampleRadio'>Red</label>
                                        </div>                                
                                    </div>
                                    <h6 className='mt-2'> Choose Size</h6>
                                    <div className='input-group'>
                                        <div className='form-check mx-1'>
                                            <input className='form-check-input' type='radio' name='exampleRadio' id='exampleRadio' />
                                            <label className='form-check-label' htmlFor='exampleRadio'>Black</label>
                                        </div>
                                        <div className='form-check mx-1'>
                                            <input className='form-check-input' type='radio' name='exampleRadio' id='exampleRadio' />
                                            <label className='form-check-label' htmlFor='exampleRadio'>Green</label>
                                        </div>
                                        <div className='form-check mx-1'>
                                            <input className='form-check-input' type='radio' name='exampleRadio' id='exampleRadio' />
                                            <label className='form-check-label' htmlFor='exampleRadio'>Red</label>
                                        </div>                                
                                    </div>
                                    <h6 className='mt-2'> Choose Size</h6>
                                    <input className='form-control text-center w-50' type='number' />
                                    <div className='input-group mt-3'>
                                        <button className='btn site-btn m-1'><i className='fa fa-shopping-cart'></i></button>
                                        <button className='btn site-btn m-1'><i className='fa fa-car'></i></button>
                                        <button className='btn site-btn m-1'><i className='fa fa-heart'></i></button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='' md={6} lg={6} sm={12} xs={12}>
                                    <h6 className='mt-2'> Details</h6>
                                    <p>
                                        {ReactHtmlParser(this.state.details)}
                                    </p>
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book.
                                    </p>
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book.
                                    </p>                            
                                </Col>
                                <Col className='' md={6} lg={6} sm={12} xs={12}>
                                    <h6 className='mt-2'> REVIEWS</h6>
                                    <p className='p-0 m-0'> 
                                        <span className='Review-Title'>Toihid</span> 
                                        <span className="text-success">
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                        </span>
                                    </p>
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book.
                                    </p>  

                                    <p className='p-0 m-0'> 
                                        <span className='Review-Title'>Toihid</span> 
                                        <span className="text-success">
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>

                                        </span>
                                    </p>
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book.
                                    </p>  

                                    <p className='p-0 m-0'> 
                                        <span className='Review-Title'>Toihid</span> 
                                        <span className="text-success">
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                            <i className="fa fa-star"/>
                                        </span>
                                    </p>
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book.
                                    </p>                                                          
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Container>
            </Fragment>
        );
    }
}

ProductDetails.propTypes = {};

export default ProductDetails;
