import React, { Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import HomeTop from '../components/home/HomeTop';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import HomeTopMobile from '../components/home/HomeTopMobile';
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import ListByCategory from '../components/productDetails/ListByCategory';
import { withRouter } from "react-router-dom";
import Axios from "axios";
import ApiUrl from '../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';

class ProductListByCategory extends React.Component {
    constructor(props) {
        super(props);  
        const url = window.location.href;
        const slug = url.substring(url.lastIndexOf('/') + 1);        
        this.state = {
            slug : slug,
            productList : [],
            categoryName : '',

        };
    }

    componentDidMount(){
        
        window.scroll(0,0);     
        Axios.get(ApiUrl.ProductListByCategory(this.state.slug)).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonData = response.data;
                this.setState({productList:jsonData});
                this.setState({categoryName:jsonData[0]['category']});
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });   
        
    }

    render() {
        return (
            <Fragment>

                <div className='Desktop'>
                    <NavMenuDesktop></NavMenuDesktop>
                </div>
                <div className='Mobile'>
                    <NavMenuMobile></NavMenuMobile>
                </div>
                <ListByCategory categoryName={this.state.categoryName} productList={this.state.productList}></ListByCategory>

                <div className='Desktop'>
                    <FooterDesktop></FooterDesktop>
                </div>
                <div className='Mobile'>
                    <FooterMobile></FooterMobile>
                </div>                
                
               
            </Fragment>
        );
    }
}

ProductListByCategory.propTypes = {};

export default ProductListByCategory;
