import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import {Container, Row, Col, Card} from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';
import {Link} from "react-router-dom";
import NewArrivalPlaceholder from '../placeholder/NewArrivalPlaceholder';

class NewArrival extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productList : [],
            isLoading: 'TopSection',
            mainDiv: "d-none"
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next(){
        this.slider.slickNext();
    }

    previous(){
        this.slider.slickPrev();
    }

    componentDidMount(){
        Axios.get(ApiUrl.ProductListByRemark('NEW')).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonData = response.data;
                this.setState({productList:jsonData, isLoading:"d-none", mainDiv:""});
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });   
    
    }

    render() {

        const productList=this.state.productList;
        const productListView = productList.map((productList,i)=>{
          if(productList.special_price > 0){
              return <div key={i.toString()}>
              <Link to={'productDetails/'+productList.id}>
                    <Card className='card text-center image-box w-100' >
                          <Card.Body className='image-box card'>
                              <img src={productList.image} />
                              <p className='product-name-on-card'> {productList.title}</p>
                              <p className='product-price-on-card'>Price: 
                                  <strike class="text-secondary">{productList.price} </strike> &nbsp; {productList.special_price} Sek
                              </p>
                           </Card.Body>
                    </Card>
              </Link>     
          </div>                                            
          }else{
              return <div key={i.toString()}>
                        <Link to={'productDetails/'+productList.id}>
                            <Card className='card text-center image-box w-100' >       
                                <Card.Body>
                                    <img src={productList.image} />
                                    <p className='product-name-on-card'> {productList.title} </p>
                                    <p className='product-price-on-card'>Price: {productList.price} Sek</p>
                                </Card.Body>
                            </Card>    
                        </Link>                
                    </div>                
            }
        }); 

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplayspeed:3000,
            autoplay: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        
        return (
            <div>
                <NewArrivalPlaceholder isLoading={this.state.isLoading}></NewArrivalPlaceholder>
                <div className={this.state.mainDiv}>
                    <Container className='text-center BetweenTwoSection' fluid= {true}>
                            <h4 className='section-title px-0 mx-0'>
                                NEW ARRIVAL
                                <a className='btn btn-sm ml-2 site-btn'>
                                    <i className='fa fa-angle-left' onClick = {this.previous}></i>
                                </a>
                                <a className='btn btn-sm ml-2 site-btn'>
                                    <i className='fa fa-angle-right' onClick = {this.next}></i>
                                </a> 
                            </h4>   
                                <Slider ref={ c => (this.slider=c)} {...settings}>
                                    {productListView}
                                </Slider>
                    </Container>
                </div>
            </div>
        );
    }
}

NewArrival.propTypes = {};

export default NewArrival;
