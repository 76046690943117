import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Col, Row,Card, Button, Form, Container } from 'react-bootstrap';
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            about:'',
            loaderDiv: "",
            mainDiv: "d-none"
        };
    }

    componentDidMount(){

        let SiteInfoAbout = sessionStorage.getItem("SiteInfoAbout");

        if(SiteInfoAbout == null){
            Axios.get(ApiUrl.SendSiteInfo).then(response=>{
                let statusCode = response.status;
                if(statusCode == 200){
                    let jsonData = (response.data)[0]['about'];
                    this.setState({about:jsonData, loaderDiv:"d-none", mainDiv:""});
                    sessionStorage.setItem("SiteInfoAbout",jsonData);
                }
            }).catch( error=>{
                toast.error("Something wrong! please pry again");
            });
        }else{
            this.setState({about: SiteInfoAbout,loaderDiv:"d-none", mainDiv:""});
        }
    }

    render() {
        return (
            <Fragment>
                <Container className="TopSection animated slideInDown pt-5 pb-5">
                    <Row className="d-flex p-1 justify-content-center">
                        <Col className={this.state.loaderDiv } md={8} lg={8} sm={12} xs={12}>
                            <div class="ph-item">
                                <div class="ph-col-12">
                                    <div class="ph-row">
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="ph-item">
                                <div class="ph-col-12">
                                    <div class="ph-row">
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                    </div>
                                </div>
                            </div>                           
                        </Col>

                        <Col className={this.state.mainDiv} md={8} lg={8} sm={12} xs={12}>
                                { ReactHtmlParser(this.state.about)}
                        </Col>                        
                    </Row>
                </Container>  
            </Fragment>
        );
    }
}

AboutUs.propTypes = {};

export default AboutUs;
