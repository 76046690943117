import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import HomeTop from '../components/home/HomeTop';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import HomeTopMobile from '../components/home/HomeTopMobile';
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import UserOnBoard from '../components/common/UserOnBoard';
import Contact from '../components/common/Contact';
import ProductDetails from '../components/productDetails/ProductDetails';
import SuggestedProducts from '../components/productDetails/SuggestedProducts';
import Axios from "axios";
import ApiUrl from '../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';

class ProductDetailsPage extends React.Component {
    constructor(props) {
        super(props);    
        this.state = {
            productData : []
        };

    }

    componentDidMount(){
        window.scroll(0,0);  
    }

    render() {
        return (
            <Fragment>
                <div className='Desktop'>
                    <NavMenuDesktop></NavMenuDesktop>
                </div>
                <div className='Mobile'>
                    <NavMenuMobile></NavMenuMobile>
                </div>
                <ProductDetails productData={this.state.productData} id={this.state.id} ></ProductDetails>
                <SuggestedProducts></SuggestedProducts>

                <div className='Desktop'>
                    <FooterDesktop></FooterDesktop>
                </div>
                <div className='Mobile'>
                    <FooterMobile></FooterMobile>
                </div>                
                
               
            </Fragment>
        );
    }
}

ProductDetailsPage.propTypes = {};

export default ProductDetailsPage;
