import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Card} from 'react-bootstrap';
import MegaMenu from './MegaMenu';
import SliderHome from './SliderHome';

import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';

class HomeTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuData : []
        };
    }

    componentDidMount(){
        Axios.get(ApiUrl.SendCategoryDetails).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonData = response.data;
                this.setState({menuData:jsonData});
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });   
         
    }

    render() {
        return (
            <Fragment>
                <Container className='p-0 topSection' fluid={true}>
                    <Row>
                        <Col lg={3} md = {3} sm = {12} className="m-0">
                            <MegaMenu menuData={this.state.menuData}></MegaMenu>
                        </Col>
                        <Col lg={9} md = {9} sm = {12} className="m-0 p-0">
                            <SliderHome></SliderHome>
                        </Col>                        
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

HomeTop.propTypes = {};

export default HomeTop;
