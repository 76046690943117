import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Card} from 'react-bootstrap';
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';
import {Link} from "react-router-dom";
import SpecialCollectionPlaceholder from '../placeholder/SpecialCollectionPlaceholder';

class Collection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productList : [],
            isLoading: 'TopSection',
            mainDiv: "d-none"
        };
    }
    componentDidMount(){
        Axios.get(ApiUrl.ProductListByRemark('COLLECTION')).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonData = response.data;
                this.setState({productList:jsonData, isLoading:'d-none', mainDiv:""});
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });   
     
    }
    render() {
        const productList=this.state.productList;
        const productListView = productList.map((productList,i)=>{
          if(productList.special_price > 0){
              return <Col key={i.toString()} xl= {2} lg= {2} md={2} sm ={4} xs = {6} >
              <Link to={'productDetails/'+ productList.id}>
                  <Card className='image-box text-center card w-100'> 
                          <img src={productList.image} />
                          <Card.Body className='image-box card'>
                              <p className='product-name-on-card'> {productList.title}</p>
                              <p className='product-price-on-card'>Price: 
                                  <strike class="text-secondary">{productList.price} </strike> &nbsp; {productList.special_price} Sek
                              </p>
                           </Card.Body>
                  </Card>
              </Link>     
          </Col>                                            
          }else{
              return <Col key={i.toString()} xl= {2} lg= {2} md={2} sm ={4} xs = {6} >
                  <Link to={'productDetails/'+ productList.id}>
                        <Card className='image-box text-center card w-100'> 
                            <img src={productList.image} />
                            <Card.Body className='image-box card'>
                                <p className='product-name-on-card'> {productList.title}</p>
                                <p className='product-price-on-card'>Price: {productList.price}</p>
                            </Card.Body>
                        </Card>
                  </Link>     
              </Col>                
          }
        }); 

        return (
            <Fragment>
                <SpecialCollectionPlaceholder isLoading={this.state.isLoading}></SpecialCollectionPlaceholder>
                <div className={this.state.mainDiv}>
                    <Container className='text-center BetweenTwoSection' fluid={true}>
                        <h4 className="section-title">SPECIAL COLLECTION</h4>
                        <h6 className="section-sub-title">Some Of Our Exclusive Collection, You May Like</h6>
                        <Row>
                            {productListView}
                        </Row>
                    </Container>
                </div>

            </Fragment>
        );
    }
}

Collection.propTypes = {};

export default Collection;
