import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Navbar, Container, Row, Col, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";


class NavMenuDesktop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Navbar fixed='top' className='navbar' bg="light">
                    <Container fluid={true}>
                    
                        <Row className="w-100">
                            <Col className='p-1' lg={4} md={4} sm={12} xs={12}> 
                                <a href="#" className="btn"> 
                                    <img className="nav-logo" src="http://toihid.com/wp-content/uploads/2022/04/logo.png" alt=""/>
                                </a>
                                <Link to={"/cart"} className="cart-btn"><i className="fa fa-shopping-cart"/> 4 Iteams </Link>
                            </Col>
                            <Col className='p-1' lg={4} md={4} sm={12} xs={12}> 
                                <div className="input-group w-100">
                                    <input  name="example" list="exampleList" type="text" className="form-control" aria-label="Text input with segmented dropdown button"/>
                                    <button  type="button" className="btn  site-btn"><i className="fa fa-search"/></button>
                                </div>
                            </Col>
                            <Col className='p-1' lg={4} md={4} sm={12} xs={12}>
                                 
                                <Link to={'/favourite'} className='btn'>
                                    <i className='fa h4 fa-heart'></i>
                                    <sup>
                                        <span className='badge bg-danger'>4</span>
                                    </sup>
                                </Link>
                                <Link to={'/notification'} className='btn'>
                                    <i className='fa h4 fa-bell'></i>
                                    <sup>
                                        <span className='badge bg-danger'>4</span>
                                    </sup>
                                </Link>   
                                <a className='btn'>
                                    <i className='fa h4 fa-mobile-alt'></i>
                                </a>   
                                <Link className='btn h4' to="/onboard">LOGIN</Link>  
                                <h7 className={'hobyText'} >It's a hobby project & images from https://www.sportfiskeprylar.se/ </h7>                                                                                          
                            </Col>                                                
                        </Row>
                    </Container>
                </Navbar>                
            </Fragment>
        );
    }
}

NavMenuDesktop.propTypes = {};

export default NavMenuDesktop;
