import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Card} from 'react-bootstrap';
import MegaMenu from './MegaMenu';
import SliderHome from './SliderHome';

class HomeTopMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Container className='p-0 topSection' fluid={true}>
                    <Row>
                        <Col lg={12} md = {12} sm = {12} className="m-0 p-0">
                            <SliderHome></SliderHome>
                        </Col>                        
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

HomeTopMobile.propTypes = {};

export default HomeTopMobile;
