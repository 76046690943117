import React, {Component, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'bootstrap';

class MegaMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          menuItems: [
            {
              id: 1,
              name: 'Menu 1',
              subMenuItems: [
                { id: 11, name: 'Submenu 1-1' },
                { id: 12, name: 'Submenu 1-2' },
                { id: 13, name: 'Submenu 1-3' }
              ]
            },
            {
              id: 2,
              name: 'Menu 2',
              subMenuItems: [
                { id: 21, name: 'Submenu 2-1' },
                { id: 22, name: 'Submenu 2-2' },
                { id: 23, name: 'Submenu 2-3' }
              ]
            },
            {
              id: 3,
              name: 'Menu 3',
              subMenuItems: [
                { id: 31, name: 'Submenu 3-1' },
                { id: 32, name: 'Submenu 3-2' },
                { id: 33, name: 'Submenu 3-3' }
              ]
            },
            {
              id: 4,
              name: 'Menu 4',
              subMenuItems: [
                { id: 41, name: 'Submenu 3-1' },
                { id: 42, name: 'Submenu 3-2' },
                { id: 43, name: 'Submenu 3-3' }
              ]
            },
            {
              id: 5,
              name: 'Menu 5',
              subMenuItems: [
                { id: 51, name: 'Submenu 3-1' },
                { id: 52, name: 'Submenu 3-2' },
                { id: 53, name: 'Submenu 3-3' }
              ]
            },
            {
              id: 6,
              name: 'Menu 6',
              subMenuItems: [
                { id: 61, name: 'Submenu 3-1' },
                { id: 62, name: 'Submenu 3-2' },
                { id: 63, name: 'Submenu 3-3' }
              ]
            },
            {
              id: 7,
              name: 'Menu 7',
              subMenuItems: [
                { id: 71, name: 'Submenu 3-1' },
                { id: 72, name: 'Submenu 3-2' },
                { id: 73, name: 'Submenu 3-3' }
              ]
            }
          ],
          activeMenuId: null
        };
      }
    
      handleMenuClick = (menuId) => {
        this.setState({ activeMenuId: menuId });
      }
    
      render() {
        const { menuItems, activeMenuId } = this.state;
    
        return (
          <div className="accordionMenuDivMobile">
            <div className='accordionMenuDivInsideMobile'>
                {menuItems.map(menuItem => (
                <div key={menuItem.id} className={`menu-item ${activeMenuId === menuItem.id ? 'active' : ''}`} onClick={() => this.handleMenuClick(menuItem.id)}>
                    <button className= {`accordionMobile`}><img className='accordionMenuIconMobile' src='http://toihid.com/wp-content/uploads/2023/03/menu-iteam-e1679193054828.jpg'></img> {menuItem.name}</button>
                    {activeMenuId === menuItem.id && (
                      
                    <div className="panelMobile">
                        <ul>
                            {menuItem.subMenuItems.map(subMenuItem => (
                            <li key={subMenuItem.id} className="accordionItemMobile">{subMenuItem.name}</li>
                            ))}
                        </ul>
                    </div>
                    )}
                </div>
                ))}
            </div>
          </div>
        );
      }
    }


    MegaMenuMobile.propTypes = {};

export default MegaMenuMobile;
