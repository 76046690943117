import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Navbar, Container, Row, Col, Button} from 'react-bootstrap';
import MegaMenu from '../home/MegaMenu';
import MegaMenuMobile from '../home/MegaMenuMobile';
import { Link } from 'react-router-dom';

class NavMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SideNavState : "sideNavClose",
            ContentOverState: "ContentOverlayClose"
        };
    }


    MenuBarClickHandler = ()=>{
        this.SideNavOpernClose();
    }
    ContectOverlayClickHandler=()=>{
        this.SideNavOpernClose();
    }

    SideNavOpernClose=()=>{
        let SideNavState = this.state.SideNavState;
        let ContentOverState = this.state.ContentOverState;
        if(SideNavState === "sideNavOpen"){
            this.setState({SideNavState: "sideNavClose", ContentOverState : "ContentOverlayClose"});
        }else{
            this.setState({SideNavState: "sideNavOpen", ContentOverState : "ContentOverlayOpen"})
        }
    }
    

    render() {
        return (
            <Fragment>
    
                <Navbar fluid={true} className="fixed-top d-flex justify-content-between shadow-sm p-2 m-0 bg-white">
                     <Button onClick={this.MenuBarClickHandler} className="btn-primary-outline mx-2 navbar-brand" ><i className="fa fa-bars"></i></Button>
                    
                     <div className="float-right">
                             <Link to="/cart" className="cart-btn"><i className="fa fa-shopping-cart"/> 4 </Link>
                             <Link to="/favourite" className="btn mx-1 "> <i className='fa h4 fa-heart'></i>4</Link>
                            <Link to="/notification" className="btn mx-1 "><i className='fa h4 fa-bell'></i> 4</Link>
                            <Link to="/favourite" className="btn mx-1 float-right"><i className="fa fa-search"/> </Link>
                        </div>                    
                
                </Navbar>

                <div id="SideNavID" className={this.state.SideNavState}>
                    <Link to="/" className="btn"> <img className="nav-logo" src="http://toihid.com/wp-content/uploads/2022/04/logo.png" alt=""/></Link>
                    <hr className="my-2"/>
                   {/*<MegaMenu> </MegaMenu> */}
                   <MegaMenuMobile></MegaMenuMobile>

                </div>

                <div onClick={this.ContectOverlayClickHandler} className={this.state.ContentOverState}></div>
                   
        </Fragment>
        );
    }
}

NavMenuMobile.propTypes = {};

export default NavMenuMobile;
