import React, { Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import HomeTop from '../components/home/HomeTop';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import HomeTopMobile from '../components/home/HomeTopMobile';
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import ListByCategory from '../components/productDetails/ListByCategory';
import { withRouter } from "react-router-dom";
import Axios from "axios";
import ApiUrl from '../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';
import ListBySubCategory from '../components/productDetails/ListBySubCategory';

class ProductListBySubCategory extends React.Component {
    constructor(props) {
        super(props);  
        const url = window.location.href.replace(/%20/g, " ");;
        const subCategory = url.substring(url.lastIndexOf('/') + 1);
        const pathSegments = url.split("/");
        const category =  pathSegments[pathSegments.length - 2];   
        this.state = {
            category : category,
            subCategory : subCategory,
            productList : [],
            categoryName : '',

        };
    }

    componentDidMount(){
        window.scroll(0,0);     
        Axios.get(ApiUrl.ProductListBySubCategory(this.state.category, this.state.subCategory)).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonData = response.data;
                this.setState({productList:jsonData});
                this.setState({categoryName:jsonData[0]['category']});
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });   
        
    }

    render() {
        return (
            <Fragment>

                <div className='Desktop'>
                    <NavMenuDesktop></NavMenuDesktop>
                </div>
                <div className='Mobile'>
                    <NavMenuMobile></NavMenuMobile>
                </div>
                <ListBySubCategory subCategoryName={this.state.subCategory} productList={this.state.productList}></ListBySubCategory>

                <div className='Desktop'>
                    <FooterDesktop></FooterDesktop>
                </div>
                <div className='Mobile'>
                    <FooterMobile></FooterMobile>
                </div>                
                
               
            </Fragment>
        );
    }
}

ProductListBySubCategory.propTypes = {};

export default ProductListBySubCategory;
