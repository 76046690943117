import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import HomeTop from '../components/home/HomeTop';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import HomeTopMobile from '../components/home/HomeTopMobile';
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import UserOnBoard from '../components/common/UserOnBoard';

class UserOnBoardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <Fragment>

                <div className='Desktop'>
                    <NavMenuDesktop></NavMenuDesktop>
                </div>
                <div className='Mobile'>
                    <NavMenuMobile></NavMenuMobile>
                </div>
                <UserOnBoard></UserOnBoard>

                <div className='Desktop'>
                    <FooterDesktop></FooterDesktop>
                </div>
                <div className='Mobile'>
                    <FooterMobile></FooterMobile>
                </div>                
                
               
            </Fragment>
        );
    }
}

UserOnBoardPage.propTypes = {};

export default UserOnBoardPage;
