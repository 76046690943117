import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';

class FooterMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            about_company:'',
            address:'',
            android_app_link:'',
            ios_app_link:'',
            facebook_link:'',
            twitter_link:'',
            instagram_link:'',
            delivery_notice:'',

            loaderDiv: "",
            mainDiv: "d-none"
        };
    }

    componentDidMount(){

        let SiteInfoFooter = sessionStorage.getItem("SiteInfoFooter");
        if(SiteInfoFooter == null){
            Axios.get(ApiUrl.SendSiteInfo).then(response=>{
                let statusCode = response.status;
                if(statusCode == 200){
                    let jsonData = (response.data)[0];
                    this.setState({
                        about_company: jsonData['about_company'],
                        address: jsonData['address'],
                        android_app_link: jsonData['android_app_link'],
                        ios_app_link: jsonData['ios_app_link'],
                        facebook_link: jsonData['facebook_link'],
                        twitter_link: jsonData['twitter_link'],
                        instagram_link: jsonData['instagram_link'],
                        delivery_notice: jsonData['delivery_notice'],
                        loaderDiv:"d-none", 
                        mainDiv:""
                    });
                    sessionStorage.setItem("SiteInfoFooter",JSON.stringify(jsonData));
                }
            }).catch( error=>{
                toast.error("Something wrong! please pry again");
            });
        }else{
            let FooterDataJson = JSON.parse(SiteInfoFooter);
            this.setState({
                about_company: FooterDataJson['about_company'],
                address: FooterDataJson['address'],
                android_app_link: FooterDataJson['android_app_link'],
                ios_app_link: FooterDataJson['ios_app_link'],
                facebook_link: FooterDataJson['facebook_link'],
                twitter_link: FooterDataJson['twitter_link'],
                instagram_link: FooterDataJson['instagram_link'],
                delivery_notice: FooterDataJson['delivery_notice'],
                loaderDiv:"d-none", 
                mainDiv:""
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid={true}>
                    <Row className='px-0 my-5'>
                            <Col className='p-2 text-center' lg={3} md={3} sm={6} xs={12}>
                                <h5 className="footer-menu-title">ABOUT COMPANY</h5>
                                <p> {this.state.about_company}</p>
                                <h5 className="footer-menu-title">SOCIAL LINK</h5>
                                <a target="_blank" href={this.state.facebook_link}><i className="fab m-1 h4 fa-facebook"/></a>
                                <a target="_blank" href={this.state.instagram_link}><i className="fab m-1 h4 fa-instagram"/></a>
                                <a target="_blank" href={this.state.twitter_link}><i className="fab m-1 h4 fa-twitter"/></a>                         
                            </Col>
                            <Col className='p-2 text-center' lg={3} md={3} sm={6} xs={12}>
                                <h5 className="footer-menu-title">ABOUT COMPANY</h5>
                                <Link to={"/about-us"} className='footer-link'>About us</Link> 
                                <Link to={"/contact"} className='footer-link'>Contact Us</Link>                   
                            </Col>
                            <Col className='p-2 text-center' lg={3} md={3} sm={6} xs={12}>
                                <h5 className="footer-menu-title">MORE INFO</h5>
                                <Link to={"/purchase"} className="footer-link">How To Purchase</Link><br/>
                                <Link to={"/policy"} className="footer-link">Privacy Policy</Link><br/>
                                <Link  to={"/refund"} className="footer-link">Refund Policy</Link><br/>                   
                            </Col>
                            <Col className='p-2 text-center' lg={3} md={3} sm={6} xs={12}>
                                <h5 className="footer-menu-title">DOWNLOAD APP</h5>
                                <a target="_blank" href={this.state.ios_app_link}><img className="" src="http://toihid.com/wp-content/uploads/2023/04/ios-removebg-preview-e1680367911440.png" alt="ios logo"/></a><br/>
                            <a target="_blank" href={this.state.android_app_link}><img className="mt-2" src="http://toihid.com/wp-content/uploads/2023/04/android-removebg-preview-e1680367851886.png" alt="android logo"/></a>                     
                            </Col>                                                                       
                        </Row>
                </Container>   
                <Container fluid={true} className=" m-0 pt-3 pb-1 bg-dark">
                    <Container className="">
                        <Row className="px-0 text-white">
                            <p>aertgertwertwert</p>
                        </Row>
                    </Container>
                </Container>                             
            </Fragment>
        );
    }
}

FooterMobile.propTypes = {};

export default FooterMobile;
