import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';

class Favourite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Container className='text-center mt-5 pt-5' fluid={true}>
                    <h4 className='section-title'>My favourite Items</h4>
                    <h6 className='section-sub-title pb-3'>Some of our Exclusive collection, You may like </h6>
                    <Row>
                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/p17.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>
                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/p21.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>     
                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/32.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>  
                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/p20.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>   

                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/p20.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>  

                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/p20.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>                                                 
                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/p20.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>  

                        <Col className='p-1' xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Card className='image-box text-center card w-100' >
                                    <img src="http://toihid.com/wp-content/uploads/2023/03/p20.webp" />
                                    <Card.Body>
                                        <p className='product-name-on-card'> Product name comes here</p>
                                        <p className='product-price-on-card'>Price: 3000 Sek</p>
                                        <Button className='btn site-btn btn-sm mt-1'><i className='fa fa-trash-alt'></i> Remove </Button>
                                    </Card.Body>
                            </Card>
                        </Col>  

                    </Row>

                </Container>
            </Fragment>
        );
    }
}

Favourite.propTypes = {};

export default Favourite;
