import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Col, Row,Card, Button, Form, Container } from 'react-bootstrap';
import Validations from '../../validation/Validations';
import axios from 'axios';
import ApiUrl from '../../../api/ApiUrl';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:"",
            mobile: "",
            msg:""
        };
    }

    nameOnChange=(event)=>{
       let name = event.target.value;
       this.setState({name:name});
    }
    mobileOnChange=(event)=>{
        let mobile = event.target.value;
        this.setState({mobile:mobile});
     }
    msgOnChange=(event)=>{
        let msg = event.target.value;
        this.setState({msg:msg});
     }
     onFormSubmit=(event)=>{
        let name = this.state.name;
        let mobile = this.state.mobile;
        let msg = this.state.msg;
        let sendBtn = document.getElementById("sendBtn");
        let contactForm = document.getElementById("contactForm");

        if(name.length==0){
            toast.error("Name required");
        }else if(!Validations.NameRegx.test(name)){
            toast.error("invalide name");
        }else if(mobile.length==0){
            toast.error("Mobile number required");
        }else if(!Validations.MobileRegx.test(mobile)){
            toast.error("Invalid Mobile");
        }else if(msg.length==0){
            toast.error("Message required");
        }else{
            sendBtn.innerHTML = "Sending.....";
            let MyFormData = new FormData();
            MyFormData.append("name",name);
            MyFormData.append("mobile", mobile);
            MyFormData.append("msg", msg);

            axios.post(ApiUrl.SendContactDetails,MyFormData).then(function(response){
                if(response.status == 200 & response.data==1){
                    toast.success('Request Success');
                    sendBtn.innerHTML="Sent";
                    contactForm.reset();
                }else{
                    toast.error('Request Fail! Please try again');
                    sendBtn.innerHTML="Failed";
                }
            }).catch(function(error){
                alert(error);
            });
        }


        event.preventDefault();
     }

    render() {
        return (
            <Fragment>
                <Container className="TopSection animated slideInDown pt-5 pb-5">
                    <Row className="d-flex p-1 justify-content-center">
                        <Col className="shadow-sm bg-white mt-2" md={8} lg={8} sm={12} xs={12}>
                            <Row className="text-center ">
                                <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Form id="contactForm" onSubmit={this.onFormSubmit} className='onboardForm'>
                                        <h4 className="section-title">Contact Us</h4>
                                        <h6 className="section-sub-title">Please Enter Your Mobile No, And Go Next</h6>
                                        <Form.Group className='mb-3 mt-3'>
                                            <input className="form-control" onChange={this.nameOnChange} type="text" placeholder='Your Name' />
                                        </Form.Group >
                                        <Form.Group className='mb-3 mt-3'>
                                        <input className="form-control" onChange={this.mobileOnChange} type="text" placeholder='Your Mobile' />
                                        </Form.Group>   
                                        <Form.Group className='mb-3 mt-3'>
                                            <input className="form-control" onChange={this.msgOnChange}  type="text" placeholder='Your Message' />
                                        </Form.Group>                                            
                                        <Form.Group>
                                            <Button id="sendBtn" type='submit' className='w-100 btn btn-block pure-material-button-contained site-btn'>Send</Button>
                                        </Form.Group>                                                                            
                                    </Form>
           
                                </Col>
                                <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                                     <iframe className='GoogleMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1063.1022234771413!2d11.99231737616608!3d57.79788002463419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff55c22dd4547%3A0x7a831b30894091a1!2sLillek%C3%A4rr%20Norra%2034%2C%20425%2034%20Hisings%20K%C3%A4rra!5e0!3m2!1ssv!2sse!4v1680444289665!5m2!1ssv!2sse"></iframe>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer></ToastContainer>
                </Container>  
            </Fragment>
        );
    }
}

Contact.propTypes = {};

export default Contact;
