import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
class MegaMenuLoader extends Component {
    render() {
        let isLoading=this.props.isLoading;
        return (
            <div className={isLoading}>
                <div className="shadow-sm h-100 bg-white">
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MegaMenuLoader;
