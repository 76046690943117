import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Breadcrumb, Card, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

class CartList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>

                <Container className='TopSection p-5 pb-5 text-center shadow-sm bg-white' >
                    <Row className='p-2 bg-light text-center'>
                        <Col className='p-1' key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <h5>PRODUCT CART LIST</h5>
                            <h6 className='m-0 p-0'>Total price 3000BDT | Total Item 04</h6> 
                            <Link to={"/order"} className='btn m-1 site-btn'><i className='fa fa-shoping-cart'></i> Checkout Now</Link>
                        </Col>

                    </Row>

                    <Row className='p-2'>
                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>

                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>   

                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>  

                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>  
                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>  
                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>  
                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>  
                        <Col className='p-1' key={1} lg={3} md={3} sm={6} xs={6} >
                           <Card className='cart-card w-100 image-box'>
                             <img src='https://toihid.com/wp-content/uploads/2023/04/pd-img1-1-e1681206915521.webp'></img>
                           </Card>
                           <Card.Body>
                                <h5 className='product-name-on-card mt-2 p-0'> ASUS TUF A15 FA506IU RYZEN 7 4800H</h5>
                                <p className='product-price-on-card mb-2 p-0'> Total Price: 300TK</p>
                            </Card.Body>
                            <div className='input-group m-0 p-0 w-100'>
                                <Button className='btn text-danger w-50 btn-light'> <i className='fa fa-trash-alt'></i></Button>
                                <input placeholder="4" className='form-control w-50 text-center' type="number"></input>
                            </div>
                        </Col>  
                    </Row>

                </Container>




          
                
            </Fragment>
        );
    }
}

CartList.propTypes = {};

export default CartList;
