import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Card} from 'react-bootstrap';
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import CategoryPlaceholder from '../placeholder/CategoryPlaceholder';

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            catData:[],
            isLoading: 'TopSection',
            mainDiv: "d-none"
        };
    }
    componentDidMount(){
        Axios.get(ApiUrl.SendCategoryDetails).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonData = response.data;
                this.setState({catData:jsonData, isLoading:'d-none', mainDiv:""});
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });   
     
    }
    render() {

        const catList=this.state.catData;
          const catView = catList.map((catList,i)=>{
            return <Col className= "p-1" key= {i.toString()} xl= {2} lg= {2} md={2} sm ={6} xs = {6} >
                        <Link to={"productListByCategory/"+catList.parentCategoryName}>
                            <Card  className=' w-100' >
                                <img src={catList.parentCategoryImg} />
                                <Card.Body>
                                    <h5 className='category-name text-center'>{catList.parentCategoryName}</h5>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>  
          }); 



        return (
            <Fragment>
                <CategoryPlaceholder isLoading={this.state.isLoading}></CategoryPlaceholder>
                <div className={this.state.mainDiv}>
                <Container className='categoriesHome pt-2' fluid= {true}>
                    <h4 className="section-title text-center pt-2">CATEGORIES</h4>
                    <h6 className="section-sub-title pb-2 text-center">Some Of Our Exclusive Collection, You May Like</h6>
                    <Row> {catView} </Row>   
                </Container>  
                </div>
              
            </Fragment>
        );
    }
}

Categories.propTypes = {};

export default Categories;
