class ApiUrl{
    //static BaseURL = "http://127.0.0.1:8000/api/";
    static BaseURL = "https://ecom.admin.toihid.com/api/";
    static SendVisitorDetails = this.BaseURL+"SendVisitorDetails"
    static SendContactDetails = this.BaseURL+"SendContactDetails"
    static SendSiteInfo = this.BaseURL+"SendSiteInfo"
    static SendCategoryDetails = this.BaseURL+"SendCategoryDetails"
    static ProductListByRemark(remark){
        return this.BaseURL+"ProductListByRemark/"+remark;
    }
    
    static ProductListByCategory(category){
        return this.BaseURL+"ProductListByCategory/"+category;
    }
    static ProductListBySubCategory(category,subCategory){
        return this.BaseURL+"ProductListBySubCategory/"+category+"/"+subCategory;
    }
    static SendSliderInfo = this.BaseURL+"SendSliderInfo"

    static ProductDetailsById(id){
        return this.BaseURL+"ProductDetailsById/"+id;
    }
    
}

export default ApiUrl;