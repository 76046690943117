import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {Container, Row, Col, Card} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';
import SliderLoader from '../placeholder/SliderLoader';

class SliderHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderData:[],
            isLoading: 'TopSection',
            mainDiv: "d-none"
        };
    }
    componentDidMount(){
        Axios.get(ApiUrl.SendSliderInfo).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonSliderData = response.data;
                this.setState({sliderData:jsonSliderData, isLoading: "d-none", mainDiv: ' '});
                
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });  
     
    }


    render() {

      
        const sliderData=this.state.sliderData;
        const sliderView = sliderData.map((sliderData,i)=>{
            return <div key={i.toString()} className='container-fluid bg-secondary overflow-hidden w-100 shadow-sm px-0 mx-0'>
                        <div style={{backgroundColor: sliderData.bg_color}}>
                            <div className='row card-body'>
                                <div className='col-md-6 sliderTitleDiv text-center p-5'>
                                    <h1 style={{color: sliderData.text_color}} className='sliderTitle'>{sliderData.title}</h1>
                                    <h3 style= {{color: sliderData.text_color}} className='sliderSubTitle'>{sliderData.sub_title}</h3>
                                    <Link to= {"productDetails/"+sliderData.product_id} className='btn site-btn px-5'> More Info</Link>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <img className='sliderImg' src={sliderData.image} />
                                </div>
                            </div>
                        </div>
            </div>
        });

        const settings = {
            dots: true,
            infinite: true,
            autoplay:true,
            autoplaySpeed:3000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          };

        return (
        <div>
            <SliderLoader isLoading={this.state.isLoading}></SliderLoader>
            <div>
                <Slider {...settings}>
                {sliderView}
                </Slider>
            </div>

        </div>
        );
    }
}

SliderHome.propTypes = {};

export default SliderHome;
