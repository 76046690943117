import React, {Component,Fragment} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Breadcrumb, Card, Modal, Button} from "react-bootstrap";


class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    handleClose = () => {
        this.setState({show: false});
    }
    handleShow = () => {
        this.setState({show:true});
    }

    

    render() {
        return (
            <Fragment>
                <Container className='TopSection pt-4'>
                    <Row>
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-primary m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Unread</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-success m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Read</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-success m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Read</p>
                                </Card.Body>
                            </Card>
                        </Col>  
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-primary m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Unread</p>
                                </Card.Body>
                            </Card>
                        </Col>    
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-success m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Read</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-success m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Read</p>
                                </Card.Body>
                            </Card>
                        </Col>  
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-primary m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Unread</p>
                                </Card.Body>
                            </Card>
                        </Col>                          
                        <Col className='p-1' lg={6} md={6} sm={12} xs={12}>
                            <Card className='notification-card' onClick={this.handleShow}>
                                <Card.Body>
                                    <h6> Loren Ipsum is simply dummy text of the printing</h6>
                                    <p className='py-1 px-0 text-success m-0'><i className='fa fa-bell'></i> Date: 22/12/2010 | Status: Read</p>
                                </Card.Body>
                            </Card>
                        </Col> 
                    </Row>

                </Container>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <h6><i className='fa fa-bell'></i> Date: 22/12/2010 </h6>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Loren Ipsum is simply dummy text of the printing Loren Ipsum is simply dummy text of the printing</p>
                        <p>Loren Ipsum is simply dummy text of the printing Loren Ipsum is simply dummy text of the printing</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

Notification.propTypes = {};

export default Notification;
