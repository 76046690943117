import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FeaturedProducts from '../components/home/FeaturedProducts';
import Categories from '../components/home/Categories';
import Collection from '../components/home/Collection';
import NewArrival from '../components/home/NewArrival';
import HomeTop from '../components/home/HomeTop';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import HomeTopMobile from '../components/home/HomeTopMobile';
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import Axios from "axios";
import ApiUrl from '../../api/ApiUrl';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
          window.scroll(0,0);
          this.GetVisitorDetails();
    }
   


    GetVisitorDetails = ()=>{
        Axios.get(ApiUrl.SendVisitorDetails).then().catch();
    }

    render() {
        return (
            <Fragment>

                <div className='Desktop'>
                    <NavMenuDesktop></NavMenuDesktop>
                    <HomeTop></HomeTop>
                </div>
                <div className='Mobile'>
                    <NavMenuMobile></NavMenuMobile>
                    <HomeTopMobile></HomeTopMobile>
                </div>

                <NewArrival></NewArrival>
                <FeaturedProducts></FeaturedProducts>
                <Categories></Categories>
                <Collection></Collection>
                <div className='Desktop'>
                    <FooterDesktop></FooterDesktop>
                </div>
                <div className='Mobile'>
                    <FooterMobile></FooterMobile>
                </div>                
                
               
            </Fragment>
        );
    }
}

HomePage.propTypes = {};

export default HomePage;
