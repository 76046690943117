import React, {Component, Fragment} from 'react';
 import PropTypes from 'prop-types';
 import {Container, Row, Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Axios from "axios";
import ApiUrl from '../../../api/ApiUrl';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';    
import { toast } from 'react-toastify';
import FeaturedProductLoader from '../placeholder/FeaturedProductLoader';
 
 class FeaturedProducts extends React.Component {
     constructor(props) {
         super(props);
         this.state = {
            productList : [],
            isLoading: 'TopSection',
            mainDiv: "d-none"
         };
     }
     componentDidMount(){
        Axios.get(ApiUrl.ProductListByRemark('FEATURED')).then(response=>{
            let statusCode = response.status;
            if(statusCode == 200){
                let jsonData = response.data;
                this.setState({productList:jsonData, isLoading:"d-none", mainDiv: " "});
            }
        }).catch( error=>{
            toast.error("Something wrong! please pry again");
        });   
     
    }

     render() {

        const productList=this.state.productList;
          const productListView = productList.map((productList,i)=>{
            if(productList.special_price > 0){
                return <Col key={i.toString()} xl= {2} lg= {2} md={2} sm ={4} xs = {6} >
                <Link to={'productDetails/'+productList.id}>
                    <Card>
                            <Card.Body className='image-box card'>
                                <img src={productList.image} />
                                <p className='product-name-on-card'> {productList.title}</p>
                                <p className='product-price-on-card'>Price: 
                                    <strike class="text-secondary">{productList.price} </strike> &nbsp; {productList.special_price} Sek
                                
                                </p>
                            </Card.Body>
                    </Card>
                </Link>     
            </Col>                                            
            }else{
                return <Col key={i.toString()} xl= {2} lg= {2} md={2} sm ={4} xs = {6} >
                    <Link to={'productDetails/'+productList.id}>
                        <Card>
                                <Card.Body className='image-box card'>
                                    <img src={productList.image} />
                                    <p className='product-name-on-card'> {productList.title}</p>
                                    <p className='product-price-on-card'>Price: {productList.price}</p>
                                </Card.Body>
                        </Card>
                    </Link>     
                </Col>                
            }


          }); 

         return (
            <Fragment>
                
                <FeaturedProductLoader isLoading={this.state.isLoading}></FeaturedProductLoader>
                <div className={this.state.mainDiv} >
                    <Container fluid= {true}>
                        <h4 className="section-title text-center pt-2">FEATURED PRODUCTS</h4>
                        <h6 className="section-sub-title text-center pb-1">Some Of Our Exclusive Collection, You May Like</h6>
                            <Row>{productListView}</Row>
                    </Container>
                </div>
            </Fragment>
         );
     }
 }

 FeaturedProducts.propTypes = {};
 
 export default FeaturedProducts;
 