import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import HomeTop from '../components/home/HomeTop';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import HomeTopMobile from '../components/home/HomeTopMobile';
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import UserOnBoard from '../components/common/UserOnBoard';
import Contact from '../components/common/Contact';

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>

                <div className='Desktop'>
                    <NavMenuDesktop></NavMenuDesktop>
                </div>
                <div className='Mobile'>
                    <NavMenuMobile></NavMenuMobile>
                </div>
                <Contact></Contact>

                <div className='Desktop'>
                    <FooterDesktop></FooterDesktop>
                </div>
                <div className='Mobile'>
                    <FooterMobile></FooterMobile>
                </div>                
                
               
            </Fragment>
        );
    }
}

ContactPage.propTypes = {};

export default ContactPage;
