import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Routes ,Route } from 'react-router-dom';
import Homepage from '../pages/HomePage';
import UserOnBoardPage from '../pages/UserOnBoardPage';
import ContactPage from '../pages/ContactPage';
import RefundPage from '../pages/RefundPage';
import PolicyPage from '../pages/PolicyPage';
import PurchasePage from '../pages/PurchasePage';
import AboutUsPage from '../pages/AboutUsPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';
import NotificationPage from '../pages/NotificationPage';
import Favourite from '../components/favourite/Favourite';
import FavouritePage from '../pages/FavouritePage';
import CartPage from '../pages/CartPage';
import OrderPage from '../pages/OrderPage';
import ProductListByCategory from '../pages/ProductListByCategory';
import ProductListBySubCategory from '../pages/ProductListBySubCategory';


class AppRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Routes>
                    <Route path = "/" element={<Homepage/>}></Route>
                    <Route path = "/onboard" element={<UserOnBoardPage/>}></Route>
                    <Route path = "/contact" element={<ContactPage/>}></Route>
                    <Route path = "/refund" element={<RefundPage/>}></Route>
                    <Route path = "/policy" element={<PolicyPage/>}></Route>
                    <Route path = "/purchase" element={<PurchasePage/>}></Route>
                    <Route path = "/about-us" element={<AboutUsPage/>}></Route>
                    <Route path = "/productDetails/:id" element={<ProductDetailsPage/>}></Route>
                    <Route path = "/notification" element={<NotificationPage/>}></Route>
                    <Route path = "/favourite" element={<FavouritePage/>}></Route>
                    <Route path = "/cart" element={<CartPage/>}></Route>
                    <Route path = "/order" element={<OrderPage/>}></Route>
                    <Route path = "/productListByCategory/:category" element={<ProductListByCategory />}></Route>
                    <Route path = "/productListBySubCategory/:category/:subCategory" element={<ProductListBySubCategory />}></Route>
                    
                </Routes>
            </Fragment>
        );
    }
}

AppRoute.propTypes = {};

export default AppRoute;
