import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import {Container, Row, Col, Breadcrumb, Card} from "react-bootstrap";
import { Link } from 'react-router-dom';

class SuggestedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Container className='text-center BtweenTwoSection pt-4'>
                    <h4 className='section-title'> YOU MAY LIKE</h4>
                    <h6 className='section-sub-title pb-3'> Some of our exclusive Collection, you may like</h6>
                    <Row>
                        <Col className='p-1' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Link to={'/productDetails'}>
                                <Card className='card w-100 image-box'>
                                    <img src='https://toihid.com/wp-content/uploads/2023/04/p-img3.webp'></img>
                                    <Card.Body>
                                        <h5 className='product-name-on-card'> ASUS RUF FA506IU RYZEN</h5>
                                        <p className='product-price-on-card'>Price: 3000TK</p>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col className='p-1' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Link to={'/productDetails'}>
                                <Card className='card w-100 image-box'>
                                    <img src='https://toihid.com/wp-content/uploads/2023/04/p-img3.webp'></img>
                                    <Card.Body>
                                        <h5 className='product-name-on-card'> ASUS RUF FA506IU RYZEN</h5>
                                        <p className='product-price-on-card'>Price: 3000TK</p>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col className='p-1' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Link to={'/productDetails'}>
                                <Card className='card w-100 image-box'>
                                    <img src='https://toihid.com/wp-content/uploads/2023/04/p-img3.webp'></img>
                                    <Card.Body>
                                        <h5 className='product-name-on-card'> ASUS RUF FA506IU RYZEN</h5>
                                        <p className='product-price-on-card'>Price: 3000TK</p>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col className='p-1' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Link to={'/productDetails'}>
                                <Card className='card w-100 image-box'>
                                    <img src='https://toihid.com/wp-content/uploads/2023/04/p-img3.webp'></img>
                                    <Card.Body>
                                        <h5 className='product-name-on-card'> ASUS RUF FA506IU RYZEN</h5>
                                        <p className='product-price-on-card'>Price: 3000TK</p>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>                        
                    </Row>
                </Container>

            </Fragment>
        );
    }
}

SuggestedProducts.propTypes = {};

export default SuggestedProducts;
