import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class ListByCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName: this.props.categoryName
        };
    }

    render() {
        const productList = this.props.productList;
          const productListView = productList.map((productList,i)=>{
            if(productList.special_price > 0){
                return <Col key={i.toString()} xl= {2} lg= {2} md={2} sm ={4} xs = {6} >
                <Link to={'/productDetails/'+ productList.id}>
                    <Card>
                            <Card.Body className='image-box card'>
                                <img src={productList.image} />
                                <p className='product-name-on-card'> {productList.title}</p>
                                <p className='product-price-on-card'>Price: 
                                    <strike class="text-secondary">{productList.price} </strike> &nbsp; {productList.special_price} Sek
                                
                                </p>
                            </Card.Body>
                    </Card>
                </Link>     
            </Col>                                            
            }else{
                return <Col key={i.toString()} xl= {2} lg= {2} md={2} sm ={4} xs = {6} >
                    <Link to={'/productDetails/'+ productList.id}>
                        <Card>
                                <Card.Body className='image-box card'>
                                    <img src={productList.image} />
                                    <p className='product-name-on-card'> {productList.title}</p>
                                    <p className='product-price-on-card'>Price: {productList.price}</p>
                                </Card.Body>
                        </Card>
                    </Link>     
                </Col>                
            }
          }); 


          return (
            <Fragment>
                <br/>
                <Container fluid= {true} className='text-center BetweenTwoSection pt-5'>
                    <h4 className='section-title pb-3 pt-2'>{ this.state.categoryName}</h4>
                    <Row>{productListView}</Row>
                </Container>
                
            </Fragment>
         );
    }
}

ListByCategory.propTypes = {};

export default ListByCategory;
